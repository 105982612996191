import { createContext, useReducer, useContext } from "react"
import appReducer from "./appReducer"
import { initialState } from "./appReducer"

const AppContext = createContext(initialState)

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initialState)

    const updateProjects = (updates) => {
        dispatch({
            type: "UPDATE_PROJECTS",
            payload: updates
        });
    };

    const setDeviceType = (deviceType) => {
        dispatch({
            type: "SET_DEVICE_TYPE",
            payload: deviceType
        })
    }

    const setAuthState = (logged, authToken) => {
        dispatch({
            type: "SET_AUTH_STATE",
            payload: { logged, authToken }
        })
    }

    const store = {
        state,
        updateProjects,
        setDeviceType,
        setAuthState
    }
    return <AppContext.Provider value={store}> {children} </AppContext.Provider>
}

const useAppState = () => {
    const context = useContext(AppContext)
    return context
}

export default useAppState