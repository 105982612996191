import useAppState from "./appContext"
import { useEffect } from "react"
import axios from "axios"

const useGetProjects = () => {
    const { state, updateProjects } = useAppState()
    
    useEffect(() => {
        if (state.projects.state === null) {            
            updateProjects({ state: "loading" })

            const fetchProjects = async () => {
                try {
                    const startTime = Date.now(); // Démarrer le chronomètre
                    const response = await axios.get(`${process.env.REACT_APP_API_PATH}/api/projects`)
                    
                    if (response.status) {
                        updateProjects({ status: response.status })
                    }
                    if (response.data.length > 0) {
                        updateProjects({ projectsList: response.data })
                    }

                    const elapsedTime = Date.now() - startTime
                    const waitTime = Math.max(1000 - elapsedTime, 0)

                    await new Promise(resolve => setTimeout(resolve, waitTime))
                } catch (error) {
                    console.error('There was an error fetching the projects!', error)
                } finally {
                    updateProjects({ state: "loaded" })
                }
            };

            fetchProjects()
        }
    }, [state.projects.state])
}

export default useGetProjects