import "./_loader.scss"

const Loader = () => {
    return(
        <div className="loader">
            <span>
                Loading...
            </span>
        </div>
    )
}

export default Loader