import { NavLink } from "react-router-dom"
import useAppState from "../../utils/appContext"
import "./_nav.scss"

const Nav = ({othersClass=""}) => {
    const { state } = useAppState()
    
    return(
        <nav className={`nav ${othersClass}`}>
            <NavLink to="/portfolio" className="nav__item text-medium" label="Projets">
                Mes projets
            </NavLink>
            <NavLink to="/about" className="nav__item text-medium" label="À propos">
                Qui suis-je ? 
            </NavLink>
            {state.auth.logged &&
                <NavLink to="/admin" className="nav__item text-medium" label="Admin">
                    Admin 
                </NavLink>
            }
        </nav>
    )
}

export default Nav