import Container from "../../layout/container/Container"
import Login from "../../components/login/Login";
import useAppState from "../../utils/appContext";
import ProjectPanel from "../../components/projectPanel/ProjectPanel";
import "./_backoffice.scss"

const Backoffice = () => {
  const { state } = useAppState();

  return(
    <Container>
      <div className="backoffice">
        {state.auth.logged ? 
          <ProjectPanel />
        :
          <Login />
        }
      </div>
    </Container>
  )
}

export default Backoffice