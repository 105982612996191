import { useEffect, useState, useRef } from "react"
import "./_popin.scss"

const Popin = ({children, isOpen = false, handleClose}) => {
    const [popinOpen, setPopinOpen] = useState(isOpen);
    const dialogRef = useRef();

    const handlePopinClose = () => {
        setPopinOpen(false)
        handleClose && handleClose()
    }

    const handleKeyDown = (e) => {
        if (e.key === "Escape") {
            handlePopinClose();
        }
    }

    useEffect(() => {
        setPopinOpen(isOpen);
    }, [isOpen])

    useEffect(() => {
        if (popinOpen && dialogRef) {
            dialogRef.current.focus();
        }
    }, [popinOpen])

    return(
        <dialog className="popin" open={popinOpen} onKeyDown={handleKeyDown} ref={dialogRef}>
            <div className="popin__inner">
                <div className="popin__inner__content">
                    <button onClick={handlePopinClose} className="popin__inner__content__btn-close">Fermer</button>
                    {children}
                </div>
            </div>
        </dialog>
    )
}

export default Popin