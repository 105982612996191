// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import { useState, useEffect, useRef } from 'react'
import Card from '../../components/card/Card'
import SliderFilters from '../../components/sliderFilters/SliderFilters'
import SliderNavigation from '../../components/sliderNavigation/SliderNavigation'
import Loader from '../../components/loader/Loader'
import useAppState from "../../utils/appContext"
import getTags from '../../utils/getTags'
import useGetProjects from '../../utils/useGetProjects'

// Import Swiper styles
import 'swiper/css'

import "./_portfolio.scss"

const allFilter = {
    'value': 'all',
    'label': 'tout'
}

const Portfolio = () => {
    useGetProjects()
    const { state } = useAppState()
    const sliderRef = useRef(null)
    const [activeProjects, setActiveProjects] = useState([])
    const [activeFilter, setActiveFilter] = useState("all");
    const [tags, setTags] = useState([])

    useEffect(() => {
        if (state.projects.projectsList) {
            let filteredProjects = state.projects.projectsList;
            if (activeFilter !== "all") {
                filteredProjects = state.projects.projectsList.filter(project => project.category.value === activeFilter)
            }
            setActiveProjects(filteredProjects)
        }
    }, [activeFilter, state.projects.projectsList])


    useEffect(() => {
        setActiveProjects(state.projects.projectsList)
        const dynamicTags = getTags(state.projects.projectsList)
        dynamicTags.unshift(allFilter)
        setTags(dynamicTags)
    }, [state.projects.projectsList])

    return (
        <div className='portfolio'>
            {state.projects.state !== "loaded" ?
                <Loader />
                :
                <>
                    {state.deviceType === "mobile" ?
                        <>
                            <SliderFilters tags={tags} othersClass='portfolio__filters' activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
                            {activeProjects.length > 0 ?
                                <ul className='portfolio__list'>
                                    {activeProjects.map((project, index) => (
                                        <li
                                            key={`card-${activeFilter}-${project.id}`}
                                            className='portfolio__list__item'
                                            style={{ animationDelay: `${0.2 * index}s` }}
                                        >
                                            <Card
                                                title={project.title}
                                                redirect_link={project.redirect_link}
                                                image={project.image}
                                                category={project.category.label}
                                                id={project.id}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                :
                                <span>Aucun projet</span>
                            }
                        </>
                        :
                        <>
                            <div className='portfolio__navigation'>
                                <div className='portfolio__filters'>
                                    <SliderFilters tags={tags} activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
                                </div>
                                <SliderNavigation sliderRef={sliderRef} />
                            </div>
                            <Swiper
                                slidesPerView={"auto"}
                                freeMode={{
                                    enabled: true,
                                    sticky: true
                                }}
                                className='portfolio__slider'
                                modules={[FreeMode]}
                                ref={sliderRef}
                            >
                                {activeProjects.map((project, index) => (
                                    <SwiperSlide
                                        key={`card-${activeFilter}-${project.id}`}
                                        className='portfolio__slider__item'
                                        style={{ animationDelay: `${0.2 * index}s` }}
                                    >
                                        <Card
                                            title={project.title}
                                            redirect_link={project.redirect_link}
                                            image={project.image}
                                            category={project.category.label}
                                            id={project.id}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </>
                    }
                </>
            }
        </div>
    )
}

export default Portfolio