import avatar from "../../assets/images/avatar.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./_profil.scss";

const Profil = ({ othersClass="" }) => {
    const { pathname } = useLocation();
    const [isAboutPage, setIsAboutPage] = useState(pathname === '/about');

    useEffect(() => {
        setIsAboutPage(pathname === '/about');
    }, [pathname])

    return(
        <div className={`profil ${othersClass}`}>
            <Link to={"/about"}>
                <div className={`profil__img-wrapper ${isAboutPage && 'is-hidden'}`}>
                    <div className="profil__img-content">
                        <div className="profil__img">
                            <img src={avatar} alt="Image de profil minimaliste"></img>
                        </div>
                    </div>
                </div>
                <h2 className="profil__title">Lise Denis</h2>
            </Link>
        </div>
    )
}

export default Profil