import Container from "../../layout/container/Container"
import Field from "../../components/field/Field"
import Tag from "../../components/tag/Tag"
import Popin from "../../components/popin/Popin"
import axios from "axios"
import { ReactComponent as TrashIcon } from '../../assets/trash.svg'
import { ReactComponent as BackIcon } from '../../assets/back.svg'
import useAppState from "../../utils/appContext"
import { useState, useEffect } from "react"
import "./_project.scss"
import { Link, useNavigate, useParams } from "react-router-dom"

const Project = ({type}) => {
    const { state } = useAppState()
    const navigate = useNavigate()
    const { id: projectId } = useParams()
    const [categories, setCategories] = useState([])
    const [categoryPopinOpen, setCategoryPopinOpen] = useState(false)
    const [formData, setFormData] = useState({
        title: null,
        image: null,
        redirect_link: null,
        category: null,
        active: null,
    });

    const handleDialogClose = () => {
        setCategoryPopinOpen(false)
    }

    const handleSubmitProject = (e) => {
        e.preventDefault()

        const form = e.target
        const title = form.title
        const image = form.image
        const link = form.link
        const category = form.category
        const active = form.active

        if (type === "edit") {
            axios.put(`${process.env.REACT_APP_API_PATH}/api/projects/${projectId}`, {
                "title": title.value,
                "image": image.value,
                "redirect_link": link.value,
                "categoryValue": category.value,
                "active": active.checked,
            },{
                headers: {
                    'x-auth-token': state.auth.authToken
                }
            })
            .then(function (response) {
                navigate("/admin");
            })
            .catch(function (error) {
                console.log(error)
            });
        }
        else {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/projects`, {
                "title": title.value,
                "image": image.value,
                "redirect_link": link.value,
                "categoryValue": category.value,
                "active": active.checked,
            },{
                headers: {
                    'x-auth-token': state.auth.authToken
                }
            })
            .then(function (response) {
                navigate("/admin");
            })
            .catch(function (error) {
                console.log(error)
            });
        }
    }

    const handleSubmitCategory = (e) => {
        e.preventDefault()

        const form = e.target
        const category = form.category

        axios.post(`${process.env.REACT_APP_API_PATH}/api/categories`, {
            "category": category.value,
        },{
            headers: {
                'x-auth-token': state.auth.authToken
            }
        })
        .then(function (response) {
            if (response.status === 201) {
                setCategories(response.data)
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleDeleteCategory = (e) => {
        e.preventDefault();

        const deletedItem = e.currentTarget;
        const deletedItemId = deletedItem.getAttribute("data-category-id");

        axios.delete(`${process.env.REACT_APP_API_PATH}/api/categories/${deletedItemId}`, {
            headers: {
                'x-auth-token': state.auth.authToken
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                setCategories(response.data)
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/categories`,{
            headers: {
                'x-auth-token': state.auth.authToken
            }
        })
        .then(response => {
            if(response.status === 200) {
                setCategories(response.data)
            }
        })
        .catch(error => {
            console.error('There was an error fetching the categories!', error)
        });
    }, []);

    useEffect(() => {
        if (type === "edit") {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/projects/${projectId}`,{
                headers: {
                    'x-auth-token': state.auth.authToken
                }
            })
            .then(response => {
                if(response.status === 200) {
                    const projectData = response.data;
                    setFormData({
                        title: projectData.title,
                        image: projectData.image,
                        redirect_link: projectData.redirect_link,
                        category: projectData.category.value,
                        active: projectData.active,
                    })
                }
            })
            .catch(error => {
                console.error('There was an error fetching the categories!', error)
            });
        }
    }, [type]);

    return(
        <Container>
            <div className="project">
                <Link to={-1} className="flex align-center"><BackIcon width={24} /> Revenir en arrière</Link>
                <form onSubmit={handleSubmitProject}>
                    <fieldset>
                        <legend>Ajouter un nouveau projet</legend>
                        <Field label="Titre du projet" id="title" type="text" defaultValue={formData.title}/>
                        <Field label="Image" id="image" type="text" defaultValue={formData.image}/>
                        <Field label="Lien vers le détail du projet" id="link" type="text" defaultValue={formData.redirect_link}/>
                        <div className="field-group">
                            <Field label="Catégorie" id="category" type="select" options={categories} defaultValue={formData.category} />
                            <button onClick={(e) => {e.preventDefault(); setCategoryPopinOpen(true);}}>
                                <Tag 
                                    firstcolor="light"
                                    secondcolor="primary"
                                    decorationType= "fill"
                                >
                                    Modifier les catégories
                                </Tag>
                            </button>
                        </div>
                        <Field label="Projet visible" id="active" type="checkbox" defaultValue={formData.active} />
                    </fieldset>
                    <div>
                        <button type="submit"> 
                            <Tag 
                                firstcolor="light"
                                secondcolor="primary"
                                decorationType= "fill"
                            >
                                Enregistrer
                            </Tag>
                        </button>
                    </div>
                </form>
                <Popin isOpen={categoryPopinOpen} handleClose={handleDialogClose}>
                    <form onSubmit={handleSubmitCategory}>
                        <fieldset>
                            <legend>Ajouter une catégorie</legend>
                            <Field label="Nom de la catégorie" id="category" type="text" />
                        </fieldset>
                        <div>
                            <button type="submit"> 
                                <Tag 
                                    firstcolor="light"
                                    secondcolor="primary"
                                    decorationType= "fill"
                                >
                                    Enregistrer
                                </Tag>
                            </button>
                        </div>
                    </form>

                    {categories.length > 0 &&       
                        <form>
                            <fieldset>
                                <legend>Supprimer une catégorie</legend>
                                <div className="flex flex-wrap align-center">
                                    {categories.map((item, index) => (
                                        <button className="flex align-center" key={index} data-category-id={item.id} onClick={handleDeleteCategory}>
                                            <span>
                                                {item.label}
                                            </span>
                                            <TrashIcon className="text-black" />
                                        </button>
                                    ))}
                                </div>
                            </fieldset>
                        </form>
                    }
                </Popin>
            </div>
        </Container>
    )
}

export default Project