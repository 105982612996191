import "./_tag.scss"

/**
 * Tag Component
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {'circle' | 'basic' | 'undefined'} props.layoutType
 * @param {'big' | 'medium' | 'undefined'} props.layoutSize
 * @param {'fill' | 'border' | 'null'} props.decorationType
 * @param {'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'null'} props.firstcolor
 * @param {'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'light' | 'null' } props.secondcolor
 * @returns {React.ReactNode}
 */
const Tag = ({
    children,
    layoutType="basic",
    layoutSize="medium",
    decorationType=null,
    firstcolor=null,
    secondcolor=null,
    othersClass=""}) => {
    return(
        <div className={`
            tag 
            tag--${layoutType} 
            tag--${layoutSize} 
            ${decorationType ? `tag--${decorationType}` :''} 
            ${firstcolor ? `tag--firstcolor--${firstcolor}` :''} 
            ${secondcolor ? `tag--secondcolor--${secondcolor}` :''} 
            ${othersClass}
        `}>
            {children}
        </div>
    )
}

export default Tag