import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Container from "../../layout/container/Container";
import Tag from "../tag/Tag";
import { ReactComponent as TrashIcon } from '../../assets/trash.svg'
import { ReactComponent as EditIcon } from '../../assets/edit.svg'
import useAppState from "../../utils/appContext"
import "./_projectPanel.scss"

const ProjectPanel = () => {
    const [projects, setProjects] = useState([]);
    const { state } = useAppState();

    const handleDeleteProject = (e) => {
        e.preventDefault();

        const projectID = e.currentTarget.getAttribute("data-project-id");

        axios.delete(`${process.env.REACT_APP_API_PATH}/api/projects/${projectID}`, {
            headers: {
                'x-auth-token': state.auth.authToken
            }
        })
        .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                setProjects(response.data)
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/projects`)
        .then(response => {
            if(response.data.length > 0) {
                setProjects(response.data)
            }
        })
        .catch(error => {
            console.error('There was an error fetching the projects!', error);
        });
    }, []);

    return (
        <Container>
            <div className="project-panel">
                <div className="project-panel__heading">
                    <h3 className="text-medium">Liste des projets</h3>
                    <Link to={'project'} className="project-panel__heading__cta">
                        <Tag 
                            firstcolor="light"
                            secondcolor="primary"
                            decorationType= "fill"
                        >
                            Ajouter un projet
                        </Tag>
                    </Link>
                </div>
                <div className="project-panel__projects">
                    {projects.length > 0 ? (
                        <table className="project-table">
                            <thead className="project-titled">
                                <tr>
                                    <td>
                                        ID
                                    </td>
                                    <td>
                                        Previsualisation
                                    </td>
                                    <td>
                                        Titre
                                    </td>
                                    <td>
                                        Catégorie
                                    </td>
                                    <td>
                                        Actif
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.map((project, index) => (
                                    <tr key={index}>
                                        <td>
                                            {project.id}
                                        </td>
                                        <td>
                                            <img src={`${project.image}`} className="project-details__img" style={{ width: "50px", height: "50px", objectFit: "cover"}}  />
                                        </td>
                                        <td>
                                            {project.title}
                                        </td>
                                        <td>
                                            {project.category.label}
                                        </td>
                                        <td>
                                            {project.active === true ? (
                                                <>
                                                    Actif
                                                </>
                                            )
                                                :
                                            (
                                                <>
                                                    Inactif
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            <div className="flex">
                                                <Link to={`project/${project.id}`}>
                                                    <Tag 
                                                        firstcolor="light"
                                                        secondcolor="primary"
                                                        decorationType= "fill"
                                                    >
                                                        <EditIcon />
                                                    </Tag>
                                                </Link>
                                                <button data-project-id={project.id} onClick={handleDeleteProject}>
                                                    <Tag 
                                                        firstcolor="light"
                                                        secondcolor="primary"
                                                        decorationType="fill"
                                                    >
                                                        <TrashIcon />
                                                    </Tag>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                    : (
                        <span>
                            Tu n'as pas de projet pour le moment :(
                        </span>
                    )
                }
                </div>
            </div>
        </Container>
    )
}

export default ProjectPanel