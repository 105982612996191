import Tag from "../tag/Tag"

const Filter = ({elem, activeFilter, setActiveFilter}) => {
    const handleChange = (e) => {
        setActiveFilter(e.target.value)
    }

    return (
        <label>
            <Tag 
                firstcolor="light"
                secondcolor={`${activeFilter === elem.value ? 'primary' : 'transparent'}`}
                decorationType= "fill"
            >
                {elem.label}
                <input 
                    type="radio" 
                    name="filter" 
                    value={elem.value} 
                    checked={activeFilter === elem.value}
                    onChange={handleChange}
                />
            </Tag>
        </label>
    )
}

export default Filter