import Filter from "../filter/Filter"
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'

import "./_sliderFilters.scss"

const SliderFilters = ({tags = [], activeFilter, setActiveFilter, othersClass=""}) => {    
    return (
        <Swiper
            slidesPerView={"auto"}
            freeMode={true}
            slideToClickedSlide={true}
            className={`slider-filters ${othersClass}`}
            modules={[FreeMode]}
            >
            {tags.map((elem, index) => (
                <SwiperSlide key={`filter-${index}`} className="slider-filters__filter">
                    <Filter elem={elem} activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default SliderFilters