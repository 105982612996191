export const initialState = {
    projects: {
        projectsList: [],
        status: null,
        state: null
    },
    deviceType: window.matchMedia('(max-width: 768px)').matches ? 'mobile' : 'desktop',
    auth: {
        logged: false,
        authToken: null
    }
};

const appReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case "UPDATE_PROJECTS":
            return {
                ...state,
                projects: {
                    ...state.projects,
                    ...payload // Met à jour seulement les propriétés passées dans le payload
                }
            };
        case "SET_DEVICE_TYPE":
            return {
                ...state,
                deviceType: payload
            }
        case "SET_AUTH_STATE":
            return {
                ...state,
                auth: {
                    logged: payload.logged,
                    authToken: payload.authToken
                }
            }
        default:
            throw new Error("no case for " + type)
    }
}

export default appReducer