const getTags = (projects) => {
    const uniqueTags = new Map();
    
    projects.forEach(project => {
        const value = project.category.value.trim();
        const label = project.category.label.trim();
        
        if (!uniqueTags.has(value)) {
            uniqueTags.set(value, label);
        }
    });

    return Array.from(uniqueTags.entries()).map(([value, label]) => ({ value, label }));
}

export default getTags