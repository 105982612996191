import { useState } from "react"
import "./_field.scss"

const Field = ({label, id, type, defaultValue=null, placeholder=null, options=[]}) => {

    const RenderSelect = () => {
        return (
            <select className="field__select" id={id}>
                {defaultValue && options.some(option => option.value === defaultValue) ? (
                    options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))
                ) : (
                    <>
                        <option value="">Choisis une option</option>
                        {options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </>
                )}
            </select>
        )
    }

    const RenderCheckbox = () => {
        const [checked, setChecked] = useState(defaultValue !== null || defaultValue !== undefined ? defaultValue : true);

        return (
            <label className="field__checkbox">
                <input id={id} type="checkbox" className="field__checkbox__input" checked={checked} onChange={() => setChecked(!checked)} />
                <span className="field__checkbox__slider"></span>
            </label>
        )
    }

    return (
        <div className={`field ${type === "checkbox" ? "field--checkbox" : ""}`}>
            <label className="field__label" htmlFor={id}>{label}</label>
            {(() => {
                switch (type) {
                    case 'select': return <RenderSelect />
                    case 'checkbox': return <RenderCheckbox />
                    default: return <input className="field__input" id={id} placeholder={placeholder} type={type} defaultValue={defaultValue} />
                }
            })()}
        </div>
    )
}

export default Field