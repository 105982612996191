import Field from "../field/Field"
import Tag from "../tag/Tag"
import axios from "axios"
import useAppState from "../../utils/appContext"
import { useState } from "react"
import "./_login.scss"

const Login = () => {
    const [loginState, setLoginState] = useState(null)
    const { setAuthState } = useAppState()

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const username = form.username
        const password = form.password

        axios.post(`${process.env.REACT_APP_API_PATH}/api/auth/login`, {
            "username": username.value,
            "password": password.value,
        })
        .then(function (response) {
            const status = response.status
            if (status === 200) {
                const token = response.data.token
                setAuthState(true, token)
                setLoginState("success")
            }
        })
        .catch(function (error) {
            console.log(error)
            setLoginState("error")
        });
    }

    return(
        <form className="login" onSubmit={(e) => handleSubmit(e)}>
            <fieldset>
                <legend>Se connecter</legend>
                {loginState === "error" &&
                    <span className="error-message">Impossible de vous connecter pour le moment</span>
                }
                <Field label="Nom d'utilisateur" id="username" type="text" />
                <Field label="Mot de passe" id="password" type="password" />
            </fieldset>
            <button type="submit"> 
                <Tag 
                    firstcolor="light"
                    secondcolor="primary"
                    decorationType= "fill"
                >
                    Envoyer
                </Tag>
            </button>
        </form>
    )
}

export default Login